import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This component is used to render a wide range of text styles based on
`}<a parentName="p" {...{
        "href": "/foundations/fonts"
      }}>{`Chameleon's typography system`}</a>{`.`}</p>
    <h2 {...{
      "id": "related-styles",
      "style": {
        "position": "relative"
      }
    }}>{`Related styles`}</h2>
    <p>{`We highly recommend consulting
`}<a parentName="p" {...{
        "href": "/foundations/fonts"
      }}>{`Chameleon's Typography System`}</a>{` to have an overview of the
guidelines to present your designs and written content in a consistent visual
hierarhcy that is optimized for better readability.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      